(($) => {
    let introPlayer = null;
    $("#welcome-intro-btn").fancybox({
        baseClass: "fancybox-plyr-video",
        afterShow: function () {

            introPlayer = new Plyr('#intro-player', plyrOption);
            introPlayer.on('ready', (e) => {
                console.log(introPlayer.currentTime);
                setTimeout(() => {
                    introPlayer.currentTime = 0;
                    introPlayer.play();
                }, 10);
            });

            //data-plyr-embed-id="20Ap1kH8wuU"
        },
        beforeClose: function () {
            if (introPlayer) {
                introPlayer.destroy();
                introPlayer = null;
            }
        }
    });
})(jQuery);

if ($('#course-video-player').length) {
    new Plyr('#course-video-player', plyrOption);

    const stickyBoxJoinForm = $('.sticky-box-join-form');
    stickyBoxJoinForm.css('top', stickyBoxJoinForm.offset().top);
}
const _loaderPuff = $('.loader_puff');
$('.form-join-course').on('submit', function (e) {
    let $that = $(this).addClass('was-validated');

    e.preventDefault();
    if (this.checkValidity() === false) {
        e.stopPropagation();
        /*
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'А нужно заполнить все поля, иначе кина не будет.'
        });
        */
        return false;
    }

    $.ajax({
        url: LANG + '/join-course/',
        type: 'POST',
        dataType: 'json',
        cache: false,
        data: $that.serialize(),
        beforeSend: function () {
            _loaderPuff.addClass('show');
        },
        complete: function () {
            _loaderPuff.removeClass('show');
        },
        success: function (json) {
            if (json.error) {
                //$that.removeClass('was-validated');
                $.each(json.error, (k, v) => {
                    $that.find('[name="' + k + '"]').addClass('is-invalid').siblings('.invalid-feedback').text(v);
                });
                //$that.addClass('was-validated');
            } else {
                if (json.text) {
                    Swal.fire({
                        icon: json.warning ? 'warning' : 'success',
                        text: json.text
                    });
                }
                if (!json.warning) {
                    $that.removeClass('was-validated');
                    $that[0].reset();
                }

            }
        }
    });


});

$('.form-login-course').on('submit', function (e) {
    let $that = $(this).addClass('was-validated');

    e.preventDefault();
    if (this.checkValidity() === false) {
        e.stopPropagation();
        /*
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'А нужно заполнить все поля, иначе кина не будет.'
        });
        */
        return false;
    }
    $.ajax({
        url: LANG + '/login-course/',
        type: 'POST',
        dataType: 'json',
        cache: false,
        data: $that.serialize(),
        beforeSend: function () {
            _loaderPuff.addClass('show');
        },
        complete: function () {
            _loaderPuff.removeClass('show');
        },
        success: function (json) {
            if (json.error) {
                Swal.fire({
                    icon: 'error',
                    text: json.error
                });
                $that.find(':text,:password').addClass('is-invalid');
            } else if (json.redirect) {
                location.href = json.redirect;
            }

        }
    });
});

$('[name="telephone"]').mask('+38(099)999-99-99');

$('.thumbnail_video').each(function (i, el) {
    new Plyr(el, plyrOption);
});

// Календарь
const eventsCalendarWrapper = $('#events_calendar_wrapper');
if (eventsCalendarWrapper.length) {
    eventsCalendarWrapper.on('click', '.change_month', function () {
        let ym = $(this).data('ym').split('-');
        $.ajax({
            url: LANG + '/events-events/calendarMonthEvents/',
            dataType: 'json',
            data: {
                month: ym[0],
                year: ym[1]
            },
            beforeSend: function() {},
            complete: function() {},
            success: function(json) {
                if (json) {
                    eventsCalendarWrapper.html(json);
                }
            }
        });
    });
}