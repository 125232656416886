const WIN = $(window);
const BODY = $('body');
const DOC = $(document);
const HEADER = $('header');
const MAIN = $('main');
const FOOTER = $('footer');
const LANG = location.pathname.match(/^\/ua\//) ? '/ua' : '';

/*
const plyrOption = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
    settings: ['quality', 'loop'],
    tooltips: {controls: true, seek: true, quality: true},
    invertTime: false,
    previewThumbnails: {enabled: true, src: ["https://cdn.plyr.io/static/demo/thumbs/100p.vtt", "https://cdn.plyr.io/static/demo/thumbs/240p.vtt"]},
    loop: true,
    i18n: {
        restart: 'Restart',
        rewind: 'Rewind {seektime}s',
        play: 'Смотреть',
        pause: 'Пауза',
        fastForward: 'Forward {seektime}s',
        seek: 'Seek',
        seekLabel: '{currentTime} of {duration}',
        played: 'Played',
        buffered: 'Буферизацией',
        currentTime: 'Текущее время',
        duration: 'Продолжительность',
        volume: 'Звук',
        mute: 'Отключить звук',
        unmute: 'Включить звук',
        enableCaptions: 'Enable captions',
        disableCaptions: 'Disable captions',
        download: 'Скачать',
        enterFullscreen: 'Во весь экран',
        exitFullscreen: 'Выйти из полноэкранного режима',
        frameTitle: 'Player for {title}',
        captions: 'Титры',
        settings: 'Настройки',
        menuBack: 'Вернуться к предыдущему меню',
        speed: 'Скорость',
        normal: 'Нормальный',
        quality: 'Качество',
        loop: 'Зациклить',
        start: 'Старт',
        end: 'Конец',
        all: 'Все',
        reset: 'Сброс',
        disabled: 'Disabled',
        enabled: 'Enabled',
        advertisement: 'Ad',
        qualityBadge: {
            2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD',
        },
    },
};
*/
const plyrOption = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    tooltips: {controls: true, seek: true, quality: true},
    invertTime: false,
    resetOnEnd: true,

    i18n: {
        restart: 'Restart',
        rewind: 'Rewind {seektime}s',
        play: 'Смотреть',
        pause: 'Пауза',
        fastForward: 'Forward {seektime}s',
        seek: 'Seek',
        seekLabel: '{currentTime} of {duration}',
        played: 'Played',
        buffered: 'Буферизацией',
        currentTime: 'Текущее время',
        duration: 'Продолжительность',
        volume: 'Звук',
        mute: 'Отключить звук',
        unmute: 'Включить звук',
        enableCaptions: 'Enable captions',
        disableCaptions: 'Disable captions',
        download: 'Скачать',
        enterFullscreen: 'Во весь экран',
        exitFullscreen: 'Выйти из полноэкранного режима',
        frameTitle: 'Player for {title}',
        captions: 'Титры',
        settings: 'Настройки',
        menuBack: 'Вернуться к предыдущему меню',
        speed: 'Скорость',
        normal: 'Нормальная',
        quality: 'Качество',
        loop: 'Зациклить',
        start: 'Старт',
        end: 'Конец',
        all: 'Все',
        reset: 'Сброс',
        disabled: 'Disabled',
        enabled: 'Enabled',
        advertisement: 'Ad',
        qualityBadge: {
            2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD',
        },
    },
};